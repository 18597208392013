<template>
  <div>
    <v-card-title id="head-title">Cuenta Corriente</v-card-title>
    <div id="user-list">
      <v-divider class="mt-4"></v-divider>
      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          placeholder="Buscar por nombre de usuario"
          outlined
          :append-icon="icons.mdiMagnify"
          v-model="search"
          @keyup="handleSearch"
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <v-spacer></v-spacer>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        :headers="tableColumns"
        :items="userListTable"
        :search="search"
        :page="current_page"
        :server-items-length="totalListTable"
        @update:page="changingUsersPage($event)"
        @update:items-per-page="changingUsersItemsPerPage($event)"
        :footer-props="{
          'items-per-page-options': [
            10,
            20,
            50,
          ],
          'items-per-page-text': 'Items por página',
          'page-text': '{0}-{1} de {2}',
        }"
      >
        <!-- NO data -->
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No se encontraron
              <strong>usuarios</strong>
            </v-list-item-title>
          </v-list-item>
        </template>

        <!-- name -->
        <template #[`item.name`]="{ item }">
          <span class="d-block font-weight-semibold text--primary text-truncate">
            {{item.name}}
          </span>
          <small>{{ item.role }}</small>
        </template>

        <!-- veterinary_clinics -->
        <template v-slot:item.veterinary_clinics="{ item }">
          <div v-if="item.veterinary_clinics.length">
            <v-chip
              v-for="(tutor, index) in item.veterinary_clinics"
              class="ms-1"
              :key="index"
              small
              outlined
            >
              {{ tutor.name }}
            </v-chip>
          </div>
          <div v-else class="ms-8"> - </div>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }"> 
          <v-btn
          class="ms-2"
          color="primary"
          icon
          @click="getCurrentAccount(item)"
          >
            <v-icon>
              {{ icons.mdiCreditCardSearchOutline }}
            </v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </div>

    <v-dialog 
    width="800"
    v-model="showAccount"
    persistent
    >
      <v-card>

        <v-toolbar flat class="toolbar" style="background-color: rgb(244 241 255 / 87%)">
          <v-card-title class="ps-0 mt-3">
            <span class="me-2"> Cuenta: </span>
            <span> {{selectedItemUser ? selectedItemUser.name : ''}}</span>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-select
            class="mt-3"
            :items="[
              {id:-1, name: 'Todos'},
              {id: 0, name: 'Pendiente'},
              {id: 1, name: 'Pagado'}
            ]"
            hide-details="true"
            v-model="statusFilter"
            item-text="name"
            item-value="id"
            dense
            style="width: 15%"
            label="Estado de remito"
            @change="getCurrentAccount(selectedUser)"
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn icon color="primary" class="mt-3 me-3" @click="() => printView()"> <v-icon>{{ icons.mdiPrinter }}</v-icon> </v-btn>
          <v-btn icon class="mt-3 me-1" @click="closeModal"><v-icon>{{ icons.mdiClose }}</v-icon> </v-btn>
        </v-toolbar>

        <template v-if="selectedItemTotals || selectedItem" >
          <v-data-table 
            fixed-header
            :height="selectedItem.length >=12 ? '655' : 'auto'"
            :headers="currentHeaders"
            :items="selectedItem"
            :server-items-length="totalUserInvoices"
            :page="invoices_page"
            @update:page="changingUsersInvoicesPage($event)"
            @update:items-per-page="changingInvoicesItemsPerPage($event)"
            :footer-props="{
              'items-per-page-options': [
                12,
                20,
                50,
              ],
              'items-per-page-text': 'Items por página',
              'page-text': '{0}-{1} de {2}',
            }"
          >
            
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  No se encontraron
                  <strong>facturas/pagos</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
            <!-- sku -->
            <template #[`item.sku`]="{ item }">
              <b>
                {{item.sku}}
              </b>
            </template>
            
            <!-- montos -->
            <template #[`item.monto`]="{ item }">
              <span>
                ${{item.monto ? item.monto : '-'}}
              </span>
            </template>

            <!-- status -->
            <template #[`item.status`]="{ item }">
              <span :class="item.status && item.status.id === 0 ? '' : 'success--text'">
                {{item.status ? item.status.label : '-'}}
              </span>
            </template>

            <!-- created_at -->
            <template #[`item.created_at`]="{ item }">
              {{ item.created_at | formatDateTime }}
            </template>

          
          </v-data-table>

          <v-divider></v-divider>

          <!-- FOOTER -->
          <v-card-text class="px-3 py-3"> 
           <v-row style="background-color: rgba(231, 227, 252, 0.87);">
            <v-col class="align">
              <span class="me-2"> <b>Total Facturación:</b> </span>
              <span> {{ '$ ' +  selectedItemTotals.total_invoices }}</span>
            </v-col>
            <v-col class="align">
              <span class="me-2"> <b>Total Pagados:</b> </span>
              <span> {{ '$ ' +  selectedItemTotals.total_pagado }}</span>
            </v-col>  
            <v-col class="align" style="background-color: rgb(207 200 255 / 87%);">
              <span v-if="selectedItemTotals.total >0">
                <span class="me-2"> <b> ADEUDA:</b> </span>
                <span
                  :style="{ color: saldoColor }"
                >{{ '$ ' + selectedItemTotals.total }}</span>
              </span>
              <span v-else style="color: rgb(64 164 70);"> <b>SIN DEUDAS</b></span>
            </v-col>
           </v-row>
          </v-card-text>
        </template>
        
        <v-card-text v-else class="mt-3"> 
        <div> No hay resultados disponibles con esa referencia</div>
        </v-card-text> 
           
      </v-card>
    </v-dialog>

    <AlertPopUp ref="alert_pop_up" />
  </div>
</template>

<script>
import debounce from "../../plugins/debounce.js";
import AlertPopUp from "../AlertPopUp.vue";

import { 
  mdiClose,
  mdiPrinter,
  mdiMagnify,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCreditCardSearchOutline,
} from "@mdi/js";

export default {
  data: () => {
    return {
      current_page: 1,
      page_size: 10,
      search: "",
      userListTable: [],
      totalListTable: 0,
      selectedUser: null,
      totalUserInvoices: 0,
      invoices_page: 1,
      invoices_page_size: 12,
      showAccount: false,
      selectedItem: [],
      selectedItemTotals: {},
      selectedItemUser: {},
      statusFilter: -1,
    };
  },
  filters: {
    formatDateTime(value){
      const date = value.split(' ')[0].split('-').reverse().join('/');
      const time = value.slice(11, 16);
      return `${date} - ${time} hs`;
    },
  },
  methods: {
    handleSearch: debounce(function () {
      this.current_page = 1;
      this.getUsers();
    }, 400),
    changingUsersPage(e) {
        this.current_page = e
        this.getUsers();
    },
    changingUsersItemsPerPage(e) {
      this.page_size = e
      this.getUsers();
    },
    async getUsers() {
      const data = {
        page_size: this.page_size,
        page: this.current_page,
        search: this.search,
        summary: true,
        only_users: true,
      };
      try {
        const response = await this.$api.getUsersList(data);
        this.userListTable = response.results;
        this.totalListTable = response.count;
        // console.log(this.userListTable)
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    changingUsersInvoicesPage(e) {
      this.invoices_page = e
      this.getCurrentAccount(this.selectedUser);
    },
    changingInvoicesItemsPerPage(e) {
      this.invoices_page_size = e
      this.getCurrentAccount(this.selectedUser);
    },
    async getCurrentAccount(user) {

      this.selectedUser = user

      try {
        const data = {
          page: this.invoices_page,
          page_size: this.invoices_page_size,
          status:  this.statusFilter
        }
        const response = await this.$api.getCurrentAccount(this.selectedUser.id?? user.id, data);

        this.totalUserInvoices = response.count

        this.selectedItem = JSON.parse(JSON.stringify(response.results))
        this.selectedItemTotals = JSON.parse(JSON.stringify(response.totales))
        this.selectedItemUser = user
        this.showAccount = true   
        } catch (error) {
          console.log(error);        
        }
    },
    closeModal() {
      this.showAccount = !this.showAccount
      // this.selectedUser = null
      this.statusFilter = -1
      this.selectedItem = []
      this.selectedItemTotals = 0
      this.selectedItemUser = {}
      this.invoices_page = 1
      this.invoices_page_size = 12

    },
    getLabel(key) {
      if (key === 'saldo') return 'Saldo'
      if (key === 'total_invoices') return 'Facturación total'
      if (key === 'total_pagado') return 'Total pagado'
      
      return key // Devuelve la clave original si no coincide con ninguna de las opciones anteriores.
    },
    printView() {
      window.print()
    },

  },
  mounted() {
    this.getUsers();
  },
  computed: {
    saldoColor() {
      return '#d25353'
    }
  },
  setup() {
    const tableColumns = [
      { text: "USUARIOS", value: "name", align: "left", sortable: false },
      { text: "CLINICAS", value: "veterinary_clinics", align: "left", sortable: false },
      { text: "CTA. CTE.", value: "actions", align: "left", sortable: false },
    ];

    const currentHeaders = [
      { text: "SKU", value: "sku", align: "left", sortable: false },
      { text: "MONTO", value: "monto", align: "center", sortable: false },
      { text: "ESTADO", value: "status", align: "left", sortable: false },
      { text: "FECHA y HORA", value: "created_at", align: "left", sortable: false },
    ]

    return {
      tableColumns,
      currentHeaders,
      icons: {
        mdiClose,
        mdiPrinter,
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCreditCardSearchOutline
      },
    };
  },
  components: { AlertPopUp },
};
</script>

<style >
.align {
  text-align: center;
}

@media print {
  .v-application {
    background: none !important;
  }
  
  /* hide page navs & footers */
  .app-navigation-menu,
  .v-app-bar,
  .v-footer {
    display: none !important;
  }
  
  /* hidding elements from this component */
  #user-list,
  #head-title{
    display: none !important;
  }
  
  .v-dialog {
    box-shadow: none !important;
    display: block !important;
    position: absolute !important;
    top: 0 !important;
    margin: 0 !important;
    transform: none !important;
  }
  
  .toolbar div button{
    display: none !important;
  }

  .v-data-table{
    overflow: hidden !important;
  }
  /* set no paddings.. can u adjust on print interface */
  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  /* // make flat cards for print */
  .v-card {
    padding-top:20px !important;
    box-shadow: none !important;
  }



}  
</style>
