<template>
  <div>
    <v-card>
      <CurrentAccountList />
    </v-card>
  </div>
</template>

<script>
import CurrentAccountList from '@/components/current-account/CurrentAccountList.vue';

  export default {
    components:{
    CurrentAccountList
}
    
  }
</script>