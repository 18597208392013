import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCardTitle,{attrs:{"id":"head-title"}},[_vm._v("Cuenta Corriente")]),_c('div',{attrs:{"id":"user-list"}},[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Buscar por nombre de usuario","outlined":"","append-icon":_vm.icons.mdiMagnify,"hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VSpacer)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.userListTable,"search":_vm.search,"page":_vm.current_page,"server-items-length":_vm.totalListTable,"footer-props":{
        'items-per-page-options': [
          10,
          20,
          50 ],
        'items-per-page-text': 'Items por página',
        'page-text': '{0}-{1} de {2}',
      }},on:{"update:page":function($event){return _vm.changingUsersPage($event)},"update:items-per-page":function($event){return _vm.changingUsersItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No se encontraron "),_c('strong',[_vm._v("usuarios")])])],1)]},proxy:true},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('small',[_vm._v(_vm._s(item.role))])]}},{key:"item.veterinary_clinics",fn:function(ref){
      var item = ref.item;
return [(item.veterinary_clinics.length)?_c('div',_vm._l((item.veterinary_clinics),function(tutor,index){return _c(VChip,{key:index,staticClass:"ms-1",attrs:{"small":"","outlined":""}},[_vm._v(" "+_vm._s(tutor.name)+" ")])}),1):_c('div',{staticClass:"ms-8"},[_vm._v(" - ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VBtn,{staticClass:"ms-2",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.getCurrentAccount(item)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiCreditCardSearchOutline)+" ")])],1)]}}],null,true)})],1),_c(VDialog,{attrs:{"width":"800","persistent":""},model:{value:(_vm.showAccount),callback:function ($$v) {_vm.showAccount=$$v},expression:"showAccount"}},[_c(VCard,[_c(VToolbar,{staticClass:"toolbar",staticStyle:{"background-color":"rgb(244 241 255 / 87%)"},attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"ps-0 mt-3"},[_c('span',{staticClass:"me-2"},[_vm._v(" Cuenta: ")]),_c('span',[_vm._v(" "+_vm._s(_vm.selectedItemUser ? _vm.selectedItemUser.name : ''))])]),_c(VSpacer),_c(VSelect,{staticClass:"mt-3",staticStyle:{"width":"15%"},attrs:{"items":[
            {id:-1, name: 'Todos'},
            {id: 0, name: 'Pendiente'},
            {id: 1, name: 'Pagado'}
          ],"hide-details":"true","item-text":"name","item-value":"id","dense":"","label":"Estado de remito"},on:{"change":function($event){return _vm.getCurrentAccount(_vm.selectedUser)}},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}}),_c(VSpacer),_c(VBtn,{staticClass:"mt-3 me-3",attrs:{"icon":"","color":"primary"},on:{"click":function () { return _vm.printView(); }}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPrinter))])],1),_c(VBtn,{staticClass:"mt-3 me-1",attrs:{"icon":""},on:{"click":_vm.closeModal}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)],1),(_vm.selectedItemTotals || _vm.selectedItem)?[_c(VDataTable,{attrs:{"fixed-header":"","height":_vm.selectedItem.length >=12 ? '655' : 'auto',"headers":_vm.currentHeaders,"items":_vm.selectedItem,"server-items-length":_vm.totalUserInvoices,"page":_vm.invoices_page,"footer-props":{
            'items-per-page-options': [
              12,
              20,
              50 ],
            'items-per-page-text': 'Items por página',
            'page-text': '{0}-{1} de {2}',
          }},on:{"update:page":function($event){return _vm.changingUsersInvoicesPage($event)},"update:items-per-page":function($event){return _vm.changingInvoicesItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No se encontraron "),_c('strong',[_vm._v("facturas/pagos")])])],1)]},proxy:true},{key:"item.sku",fn:function(ref){
          var item = ref.item;
return [_c('b',[_vm._v(" "+_vm._s(item.sku)+" ")])]}},{key:"item.monto",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(" $"+_vm._s(item.monto ? item.monto : '-')+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:item.status && item.status.id === 0 ? '' : 'success--text'},[_vm._v(" "+_vm._s(item.status ? item.status.label : '-')+" ")])]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.created_at))+" ")]}}],null,true)}),_c(VDivider),_c(VCardText,{staticClass:"px-3 py-3"},[_c(VRow,{staticStyle:{"background-color":"rgba(231, 227, 252, 0.87)"}},[_c(VCol,{staticClass:"align"},[_c('span',{staticClass:"me-2"},[_c('b',[_vm._v("Total Facturación:")])]),_c('span',[_vm._v(" "+_vm._s('$ ' + _vm.selectedItemTotals.total_invoices))])]),_c(VCol,{staticClass:"align"},[_c('span',{staticClass:"me-2"},[_c('b',[_vm._v("Total Pagados:")])]),_c('span',[_vm._v(" "+_vm._s('$ ' + _vm.selectedItemTotals.total_pagado))])]),_c(VCol,{staticClass:"align",staticStyle:{"background-color":"rgb(207 200 255 / 87%)"}},[(_vm.selectedItemTotals.total >0)?_c('span',[_c('span',{staticClass:"me-2"},[_c('b',[_vm._v(" ADEUDA:")])]),_c('span',{style:({ color: _vm.saldoColor })},[_vm._v(_vm._s('$ ' + _vm.selectedItemTotals.total))])]):_c('span',{staticStyle:{"color":"rgb(64 164 70)"}},[_c('b',[_vm._v("SIN DEUDAS")])])])],1)],1)]:_c(VCardText,{staticClass:"mt-3"},[_c('div',[_vm._v(" No hay resultados disponibles con esa referencia")])])],2)],1),_c('AlertPopUp',{ref:"alert_pop_up"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }